.NavLink {
    color: black;
    text-decoration: none;
  }
  
  .NavLink:hover{
    color: #ff8900 !important;
  }

  .active{
    color: #ff8900 !important;
  }

  .bounce-in-left {
    animation: bounce-in-left 1.1s both;
  }


/* ----------------------------------------------
 * Generated by Animista on 2024-3-19 0:9:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
 @keyframes bounce-in-left {
  0% {
    transform: translateX(-600px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: translateX(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: translateX(-68px);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateX(-28px);
    animation-timing-function: ease-in;
  }
  90% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateX(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
}
